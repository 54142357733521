<template>
  <div>
    <div class="debug-info" v-if="config?.keyword_box?.debug">
      <p>Modal Visible: {{ showModal }}</p>
      <p>Mouse Left: {{ mouseLeft }}</p>
      <p>Back Button Pressed: {{ backButtonPressed }}</p>
      <p>Modal Shown This Load: {{ modalShownThisLoad }}</p>
    </div>
    <Transition name="modal">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal-content" @click.stop>
          <button type="button" @click="closeModal" class="button-close">

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>

          </button>
          <h2>Weiterführende Infos im Netz </h2>
          <div v-if="config?.keyword_box?.sodad_id" ref="keywords" :class="`sodad-${config.keyword_box.sodad_id}`" data-placement="custom-placement">
            <!-- KWB BOX will be inserted here -->
          </div>
          <div v-if="false">
              <div class="label">Gesponserte Links</div>
                <ul id="kw-box" class="kw-box">
                  <li><a href="#">Wintergarten Angebote

                  </a></li>
                  <li><a href="#">
                    Wintergarten Preise</a></li>
                  <li><a href="#">Wintergarten aus Polen</a></li>
                  <li><a href="#">Wintergarten kaufen</a></li>
                  <li><a href="#">Wintergarten</a></li>
                  <li><a href="#">Wintergarten Baugenehmigung</a></li>
                </ul>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch} from 'vue';

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
});

const keywords = ref(null);

const showModal = ref(false);
const mouseLeft = ref(false);
const mouseLeftTimeout = ref(null);
const backButtonPressed = ref(false);
const modalShownThisLoad = ref(false);

const leavePage = () => {
  // Remove event listeners and navigate back immediately
  removeEventListeners();
  window.history.back();
};

const closeModal = () => {
  showModal.value = false;
  mouseLeft.value = false;
  backButtonPressed.value = false;
};

const handleMouseLeave = (event) => {
  if (modalShownThisLoad.value) return;

  if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
    mouseLeft.value = true;
    mouseLeftTimeout.value = setTimeout(() => {
      showModal.value = true;
      modalShownThisLoad.value = true;
    }, 2000);
  }
};

const handleMouseEnter = () => {
  mouseLeft.value = false;
  if (mouseLeftTimeout.value) {
    clearTimeout(mouseLeftTimeout.value);
  }
};

const handlePopState = (event) => {
  if (modalShownThisLoad.value) {
    return;
  }

  event.preventDefault();
  backButtonPressed.value = true;
  showModal.value = true;
  modalShownThisLoad.value = true;
};

const addEventListeners = () => {
  //document.documentElement.addEventListener('mouseleave', handleMouseLeave);
  //document.documentElement.addEventListener('mouseenter', handleMouseEnter);
  window.addEventListener('popstate', handlePopState);
};

const removeEventListeners = () => {
  //document.documentElement.removeEventListener('mouseleave', handleMouseLeave);
  //document.documentElement.removeEventListener('mouseenter', handleMouseEnter);
  window.removeEventListener('popstate', handlePopState);
};

const loadKeywords = () => {
  if (!props.config?.keyword_box?.sodad_id) {
    return;
  }
  (window._sodads = window._sodads || []).push(props.config.keyword_box.sodad_id);
}

watch(keywords, (newValue) => {
  if (newValue !== null && props.config?.keyword_box?.sodad_id) {
    loadKeywords();
  }
});

onMounted(() => {
  addEventListeners();
  history.pushState(null, '', window.location.href);
});

onUnmounted(() => {
  removeEventListeners();
  if (mouseLeftTimeout.value) {
    clearTimeout(mouseLeftTimeout.value);
  }
});
</script>

<style scoped>
.debug-info {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}

.modal-overlay {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  text-align: left;
  width: 1000px;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-content h2 {
  margin-top: 0;
}

.kw-box {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.kw-box li {
  margin: 0;
  padding: 0;
  width: 100%;
}

.kw-box li a {
  color: #245bc9;
  padding: 22px;
  margin: 6px;
  background: #ebeff8;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.kw-box li a:after {
  content: ">";
}

.button-close {
  border: none;
  background: none;
  font-size: 30px;
  float: right;
}

.button-close svg {
  width: 1em;
  height: 1em;
}
</style>

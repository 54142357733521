<template>
    <div class="ad-container" v-if="cross_selling_options && cross_selling_options.ad_items">
        <div class="ad-container-items">
          <div
              v-for="item in adItems"
              :key="'cs-' + item.text"
              class="ad-image-box"
              :href="item.link"
              @click="selectCrossSellingInterest(item.value)"
          >
            <img class="image" :src="item.image" :alt="item.text" srcset />
            <div
                v-if="item.text && item.text.length > 0"
                :class="textPositionCss(item.text_position)"
            >{{ item.text }}</div>
            <div v-if="isChecked(item.value)" class="overlay-checked">
              <svg style="width:72px;height:auto" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex-center">
            <EzaButton v-if="sent === false" text="Abschicken" :disabled="processing" @click.prevent="submitData"></EzaButton>
            <h4 v-if="sent">Danke! Ihre Daten wurden übermittelt.</h4>
        </div>
    </div>
</template>

<script>
import EzaButton from "../form/EzaButton.vue";
export default {
    props: {
        cross_selling_options: {
            type: Object,
        },
    },
    data() {
        return {
            crossSellingInterest: [],
            adItems: [],
            sent: false,
            processing: false,
        };
    },
    mounted () {
        if(!this.cross_selling_options && !this.cross_selling_options.ad_items){
            return;
        }
        const adItems = JSON.parse(JSON.stringify(this.cross_selling_options.ad_items));
        let result = this.shuffle(adItems)
        this.adItems = result.splice(0,3);

    },
    methods: {
        textPositionCss(position) {
            switch (position) {
                case "top":
                    return "overlay top";
                case "bottom":
                    return "overlay bottom";
                default:
                    return "overlay top";
            }
        },
        isChecked(value) {
            return this.crossSellingInterest.indexOf(value) > -1;
        },
        selectCrossSellingInterest(product) {
            if (this.crossSellingInterest.indexOf(product) === -1) {
                this.crossSellingInterest.push(product);
            }
            else {
                this.crossSellingInterest.splice(this.crossSellingInterest.indexOf(product), 1);
            }
        },
        shuffle(array) {
            //Fisher-Yates implementation
            let currentIndex = array.length, randomIndex;

            while (currentIndex != 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }

            return array;
        },
        submitData() {
            if (this.processing) {
                return;
            }
            this.processing = true;

            let data = {
                data: {
                    cross_selling_interest: this.crossSellingInterest.join(","),
                }
            };
            fetch(
                "https://einfach-zum-angebot.de/api-extra.php?key=1cr5jzs7mkcc8shs3s7qqu4d",
                {
                    method: "POST",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((resp) => {
                    return resp.json();
                })
                .then((json) => {
                    console.log(json);
                    this.sent = true;
                    this.processing = false;
                    if (json.status != "SUCCESS") {
                        throw "Error";
                    }
                })
                .catch(() => {
                    alert(
                        "Wir haben leider einen Fehler erhalten. Bitte kontaktieren Sie uns telefonisch."
                    );
                });


        }
    },
    components: { EzaButton }
}
</script>

<style scoped>
.flex-center {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.ad-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.ad-image-box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 30%;
    max-width: 250px;
    margin: 2% 1%;
  flex-direction: column;
  cursor: pointer;
  border: 3px solid rgba(30, 72, 122, 1);
  transition: all .3s ease-in-out;
  border-radius: 4px;
}

.ad-image-box:HOVER {
  border-color: var(--header-notice-bg-color);
  color: var(--header-notice-bg-color);
}

@media (max-width: 480px) {
    .ad-container {
        flex-direction: column;
        justify-content: center;
    }
    .ad-image-box {
        width: 100%;
        margin: 2% auto;
    }
}
@media (min-width: 481px) and (max-width: 960px) {
    .ad-container {
        flex-direction: row;
        justify-content: flex-start;
    }
    .ad-image-box {
        width: 100%;
        margin: 2%;
        max-width: 280px;
    }
}
.image {
    display: block;
    width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
    position: relative;
    background: rgba(30, 72, 122, 1);
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 1;
    color: white;
    font-size: 1rem;
    padding: 15px 0;
    text-align: center;
}

.overlay-checked {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    transition: 0.5s ease;
    opacity: 1;
    color:  var(--header-notice-bg-color);
    font-size: 1rem;
    text-align: center;
  background: white;
  border-radius: 50%;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.ad-container-items {
  min-height: 200px;
  display: flex;
  margin-bottom: 50px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
</style>

<template>

  <div>
    <div class="card">
      <h3 v-if="stepConfig.title">{{ stepConfig.title }}</h3>
      <p v-if="stepConfig.text" style="text-align: center; font-weight: bold;">
        {{stepConfig.text}}
      </p>
      <div v-if="stepConfig.html" v-html="stepConfig.html" style="text-align: center;">

      </div>
      <div v-if="stepConfig.button" class="card__button">
        <EzaButton margin-bottom="0" :text="stepConfig.button.text" :href="stepConfig.button.href"></EzaButton>
      </div>
    </div>
  </div>

</template>

<script>
import Tracking from "../../helpers/Tracking.js";
import EzaButton from "@/components/form/EzaButton.vue";

export default {
  name: "InfoStep",
  components: {EzaButton},
  props: ["isActive", "config", "stepConfig", "data"],
  emits: ["nextStep"],
  watch: {
    isActive(newVal) {
      if (newVal) {
        Tracking.sendPageView(this.config, this.stepConfig);
      }
    }
  },
  data: () => {
    return {
      val: null
    };
  },
  methods: {},
}
</script>

<style scoped>

h3 {
  margin-top: 0;
}

h4 {
  text-align: center;
}

.card {
  width: 800px;
  max-width: 100%;
  box-sizing: border-box;
}

.card h3 {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.card__row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.card__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

p {
  line-height: 1.5em;
}

</style>

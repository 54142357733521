<template>
    <div>
        <a
            :href="href"
            type="button"
            class="btn btn--orange btn--lg gv-form__btn-next"
            :style="style"
        >
            <span>{{text}}</span>
            <span class="btn__icon">
                <div class="icon icon--arrow-left"></div>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: '' 
        },
        disabled: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            default: '#'
        },
        marginBottom: {
            type: String,
            default: '65'
        }
    },
  computed: {
    style() {
      return {
        position: 'relative',
        marginBottom: this.marginBottom + 'px'
      }
    }
  },
}
</script>

<style scoped>
.gv-form__btn-last.btn--disabled, .gv-form__btn-next.btn--disabled {
  cursor: not-allowed;
  opacity: .5;
  opacity: 1;
}
.btn--orange:hover {
  background: linear-gradient(to bottom, #f47300 0%, #fe8e2b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f47300', endColorstr='#fe8e2b', GradientType=0);
  border: 1px solid #ff7800;
}
.gv-form__btn-next {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  float: right;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 65px;
  font-size: 22px;
  transition: background .3s ease-in-out;
}
.btn--orange {
  color: white;
  background: #ffb676;
  background: linear-gradient(to bottom, #fe8e2b 0%, #f47300 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fe8e2b', endColorstr='#f47300', GradientType=0);
  border: 1px solid #ff7800;
}
.btn--lg {
  font-size: 1.4em;
}
.btn {
  padding: 15px;
  text-decoration: none;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
    margin-bottom: 0px;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
    font-size: inherit;
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
}
.gv-form {
  text-align: center;
}
.box-index--invert .box-index__big, .box-index--invert .box-index__big__image::after, .box-index--invert .box-index__big__image::before {
  color: white;
}
body {
  font-size: 16px;
  color: #444444;
  font-family: 'Roboto', sans-serif;
}
body {
  line-height: 1;
}

.btn__icon {
  margin-top: -15px;
  margin-bottom: -15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  margin-left: 15px;
}

.icon--arrow-left {
  width: .5em;
  height: .5em;
  border-bottom: 2px solid;
  border-right: 2px solid;
  -webkit-transform: translate(-3px, 0px) rotate(-45deg);
  -ms-transform: translate(-3px, 0px) rotate(-45deg);
  transform: translate(-3px, 0px) rotate(-45deg);
}
</style>

<template>
    <div>
        <div class="debug-view">
            <table>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                <tr :key="'debug-'+k" v-for="(v, k) in data">
                    <td>{{k}}</td>
                    <td>{{v}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DebugWindow',
        props: {
            data: {
                type: Object,
                required: true
            }
        },
    }
</script>

<style scoped>
.debug-view{
  position: absolute;
  font-family: sans-serif;
  top: 150px;
  left: 20px;
  width: 200px;
  height: auto;
  z-index: 99;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(odd){background-color: white;}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #173559;
  color: white;
}
</style>